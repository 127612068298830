window.Vue = require('vue');

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

Vue.component('formulario-pedir-cambio-contrasena', require('../../componentes/sitio/FormularioPedirCambioContrasena.vue').default);

Vue.config.productionTip = false;
Vue.config.devtools = false;

if (document.getElementById("vue-formulario-pedir-cambio-contrasena")) {
    const formulario_inicio = new Vue({
        el: '#vue-formulario-pedir-cambio-contrasena',
    });
}